import { createTheme, darken, lighten, ThemeOptions } from '@mui/material'

const brand = {
  black: '#000000',
  white: '#ffffff',
  teal: '#21f4db',
  blue: '#91d0ff',
  purple: '#9a94f5',
  slate: '#2b2b2e',
  darkGray: '#c3c4c9',
  lightgray: '#e8e9ec'
} as const

const colors = {
  primary: {
    main: brand.teal
  },
  secondary: {
    main: brand.blue
  },
  tertiary: {
    main: brand.purple
  }
}

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
    h1: {
      fontSize: '2.375rem',
      fontWeight: 500,
      marginBottom: '0.75rem',
      letterSpacing: '-0.01em',

      '& + h2, & + h3, & + h4, & + h5, & + h6': {
        marginTop: '0',
        marginBottom: '0.75rem'
      }
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
      marginBottom: '0.25em',
      letterSpacing: '-0.01em'
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
      marginBottom: '0.5em'
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
      marginBottom: '0.5em'
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
      marginBottom: '0.5em'
    },
    h6: {
      fontSize: '0.875rem',
      fontWeight: 500,
      marginBottom: '0.5em'
    },
    subtitle1: {
      textTransform: 'uppercase',
      fontWeight: 700,
      letterSpacing: '0.1em'
    },
    subtitle2: {
      textTransform: 'uppercase',
      fontWeight: 700,
      letterSpacing: '0.1em'
    },
    body1: {},
    body2: {},
    button: {},
    caption: {},
    overline: {
      fontWeight: 700,
      letterSpacing: '0.1em'
    },
    monospace1: {
      fontFamily: 'Roboto Mono, monospace',
      fontSize: '1rem'
    },
    monospace2: {
      fontFamily: 'Roboto Mono, monospace',
      fontSize: '0.875rem'
    }
  },
  palette: {
    background: {
      default: brand.white,
      paper: brand.white
    },
    common: {
      black: brand.black,
      white: brand.white
    },
    primary: {
      main: brand.teal
    },
    secondary: {
      main: brand.blue
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {},
        notchedOutline: {
          borderWidth: 2,
          borderColor: brand.black,
          background: 'transparent',
          color: brand.black
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
        color: 'inherit',
        disableElevation: true
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 24
        },
        sizeLarge: {
          fontSize: '1rem'
        },
        startIcon: {
          path: {
            stroke: 'currentColor'
          }
        },

        endIcon: {
          path: {
            stroke: 'currentColor'
          }
        },
        containedInherit: ({ theme }) => ({
          backgroundColor:
            theme.palette.mode === 'dark'
              ? darken(theme.palette.text.secondary, 0.5)
              : lighten(theme.palette.text.secondary, 0.6),

          ':hover': {
            backgroundColor:
              theme.palette.mode === 'dark'
                ? darken(theme.palette.text.secondary, 0.4)
                : lighten(theme.palette.text.secondary, 0.7)
          }
        })
      },
      variants: [
        {
          props: { variant: 'gradient' },
          style: ({ theme }) => ({
            border: '1px solid transparent',
            background: `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper}) padding-box, linear-gradient(45deg,${theme.palette.secondary.main},${theme.palette.tertiary.main}) border-box`,
            '&.MuiButtonGroup-grouped:not(:first-of-type)': {
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderLeft: `1px ${theme.palette.secondary.main} solid`,
              marginLeft: -1
            },
            '&.MuiButtonGroup-grouped:not(:last-child)': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: 0
            }
          })
        }
      ]
    }
  }
} as ThemeOptions)

export default theme
