import React from 'react'
import '@scss/reset.global.scss'
import '@scss/styles.global.scss'
import { ThemeProvider } from '@context/ThemeContext'
import type { GatsbyBrowser } from 'gatsby'

export const wrapRootElement: GatsbyBrowser['wrapPageElement'] = ({
  
  element
}) => <ThemeProvider>{element}</ThemeProvider>
