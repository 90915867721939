import { useLocalStorage } from '@mantine/hooks'
import React, { ReactElement, useState } from 'react'
import { FC } from 'react'

// const isBrowser = () => typeof window !== 'undefined' && window

// const sidebar = {
//   open: (height = 1000) => ({
//     clipPath: `circle(${height * 2 + 200}px at calc(100% - 35px) 40px)`,
//     transition: {
//       type: 'spring',
//       stiffness: 20,
//       restDelta: 2
//     }
//   }),
//   closed: {
//     clipPath: 'circle(0px at calc(100% - 32px) 48px)',
//     transition: {
//       delay: 0.5,
//       type: 'spring',
//       stiffness: 400,
//       damping: 40
//     }
//   }
// }

// const sidebarWrapper = {
//   open: {
//     display: 'block'
//   },
//   closed: {
//     display: 'none',
//     transition: {
//       delay: 1.5
//     }
//   }
// }

export type TThemeContext = {
  isSidebarOpen: boolean
  toggleSidebar: () => void
  openSidebar: () => void
  closeSidebar: () => void
  headerOffset: number
  setHeaderOffset: (offset: number) => void
}

const defaultState = {
  isSidebarOpen: false,
  toggleSidebar: () => {},
  openSidebar: () => {},
  closeSidebar: () => {},
  headerOffset: 70,
  setHeaderOffset: () => {}
}

const toggleBodyScroll = (enable: boolean) => {
  if (document === undefined) return

  if (enable) {
    document.body.style.overflow = 'hidden'
    document.documentElement.style.overflow = 'hidden'
  } else {
    document.body.style.overflow = ''
    document.documentElement.style.overflow = ''
    document.body.style.overflowX = 'hidden'
    document.documentElement.style.overflowX = 'hidden'
  }
}

const ThemeContext = React.createContext<TThemeContext>(defaultState)

const ThemeProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useLocalStorage({
    key: 'Theme.isSidebarOpen',
    defaultValue: 'false'
  })
  const [headerOffset, setHeaderOffset] = useState(70)

  const setSidebar = (value: boolean) => {
    setSidebarOpen(JSON.stringify(value))
    toggleBodyScroll(value)
  }

  const toggleSidebar = () => setSidebar(isSidebarOpen === 'false')
  const openSidebar = () => setSidebar(true)
  const closeSidebar = () => setSidebar(false)

  return (
    <ThemeContext.Provider
      value={{
        isSidebarOpen: JSON.parse(isSidebarOpen),
        toggleSidebar,
        openSidebar,
        closeSidebar,
        headerOffset,
        setHeaderOffset
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
export default ThemeContext

export { ThemeProvider }
